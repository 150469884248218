.coming-soon-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/landing-back.png');
    background-size: cover;
    background-position: center;
    filter: brightness(0.5);
    /* Adjust as needed */
    z-index: -1;
}

.content {
    text-align: center;
}

.logo-icon-landing {
    position: absolute;
    top: 45px;
    left: 85px;
}

.logo-icon-landing img {
    width: 200px;
    height: auto;
}

.coming-soon-container-text {
    font-size: 76px;
    margin: 10px 0 30px 0;
    color: #ff7800;
}

.timer {
    margin-top: 20px;
    font-size: 24px;
}